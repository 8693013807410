import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './MediaCorner.css';
import { useIntersection } from '../hooks/useIntersection';
import AOS from 'aos';
import 'aos/dist/aos.css';

function MediaCorner() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="media" className="text-center mb-32 px-4">
      <h1 className="text-2xl font-bold text-blue-900 mb-8" data-aos="fade-down">
        MEDIA CORNER
      </h1>
      <div className="mx-auto max-w-screen-lg">
        <div
          ref={ref}
          className={`transform transition-all duration-1000 ease-in-out ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          <Slider {...settings} className="space-x-6">
            <div className="slide-container">
              <div className="content bg-blue-100 p-6 rounded-lg shadow-lg">
                <p className="text-lg font-bold text-blue-900 mb-3">
                  ALGO WORKS
                </p>
                <p className="text-sm text-blue-800">
                  Innovation and collaboration are at the core of Algo Works, your new tech hub in Banjara Hills. Join us on this exciting journey!
                </p>
              </div>
            </div>
            <div className="slide-container">
              <div className="content bg-yellow-100 p-6 rounded-lg shadow-lg">
                <p className="text-lg font-bold text-yellow-900 mb-3">
                  JOY BHARATH
                </p>
                <p className="text-sm text-yellow-800">
                  Discover our latest innovations as we lead the way in tech and creativity in the vibrant Banjara Hills district!
                </p>
              </div>
            </div>
            <div className="slide-container">
              <div className="content bg-green-100 p-6 rounded-lg shadow-lg">
                <p className="text-lg font-bold text-green-900 mb-3">
                  NEW VIDEO ALERT!!
                </p>
                <p className="text-sm text-green-800">
                  Catch our latest release on YouTube. Don't miss out on new updates and content!
                </p>
              </div>
            </div>
            <div className="slide-container">
              <div className="content bg-red-100 p-6 rounded-lg shadow-lg">
                <p className="text-lg font-bold text-red-900 mb-3">
                  AMIGO DOLLS AWAITS!!
                </p>
                <p className="text-sm text-red-800">
                  Exciting launch coming soon. Stay tuned as we make progress!
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default MediaCorner;

import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useIntersection } from '../hooks/useIntersection';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const services = [
    {
      name: 'CHAKRA GROUP',
      description:
        'We provide high-quality real estate and entertainment services with a commitment to integrity and customer satisfaction.',
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-900',
    },
    {
      name: 'JOY BHARATH',
      description:
        'Offering premier real estate development and entertainment solutions with a focus on quality and engagement.',
      bgColor: 'bg-yellow-100',
      textColor: 'text-yellow-900',
    },
    {
      name: 'ALGO WORKS',
      description:
        'A media business creating and distributing digital, satellite, and theatrical content in Telugu.',
      bgColor: 'bg-green-100',
      textColor: 'text-green-900',
    },
    {
      name: 'AMIGO DOLLS',
      description:
        'Engaged in media production, specializing in digital and satellite content for various platforms.',
      bgColor: 'bg-red-100',
      textColor: 'text-red-900',
    },
  ];

  return (
    <div
      ref={ref}
      className={`text-center mb-32 p-6 transform transition-all duration-1000 ease-in-out ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
      }`}
    >
      <h1 className="text-2xl md:text-3xl font-bold text-blue-900 mb-8 md:mb-10" data-aos="fade-down">
        OUR SERVICES
      </h1>
      <Slider {...settings} className="mx-4 md:mx-6">
        {services.map((service, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center h-64 w-64 md:h-72 md:w-72 ${service.bgColor} rounded-lg shadow-lg p-6 md:p-8 mx-2`}
          >
            <p className={`text-lg md:text-xl font-bold ${service.textColor} mb-3 md:mb-4`}>
              {service.name}
            </p>
            <p className="text-sm md:text-base text-gray-700 text-center">
              {service.description}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Services;
